@import '../../styles/shared.scss';

.chat-message-header-outer-container {
  @extend %flex-row-middle-spread;
  position: relative;
  border-bottom: $border-offwhite;
  background-color: transparent;
  padding: 12px;
  z-index: 2000;

  .profile-section {
    @extend %flex-row-middle;
    flex: 1;

    img {
      @extend %btn-basic;
      width: 40px;
      height: 40px;
      border-radius: 50%;
      margin-right: 8px;

      &.user {
        object-fit: cover;
      }

      &.brand {
        object-fit: contain;
      }
    }

    .empty {
      @extend %flex-center;
      width: 40px;
      height: 40px;
      border-radius: 50%;
      margin-right: 8px;
      background-color: $darkest;
      font-size: 18px;
      line-height: 18px;
      font-family: $font-header;
      text-transform: uppercase;
      color: white;
      font-weight: bold;
    }

    .info {
      @extend %btn-basic;

      .name {
        font-family: $font-header;
        font-size: 16px;
        line-height: 16px;
        font-weight: bold;
        margin-bottom: 4px;
        max-width: 200px;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      .profile {
        font-size: 11px;
        line-height: 11px;
        color: $medium;

        svg {
          margin-left: 4px;
          font-size: 9px;
        }
      }

      .chat-actions {
        @extend %flex-row-middle;
        font-size: 10px;
      }
    }
  }

  .actions {
    @extend %flex-row-middle;
    gap: 4px;

    @include md-down {
      display: none;
    }

    .action {
      @extend %flex-row-middle;
      gap: 4px;
      padding: 6px 10px;
      height: 30px;
      min-width: 30px;
      border-radius: 8px;
      border: $border-lightest;
      cursor: pointer;
      color: $medium;

      @include xl-down {
        padding: 4px 6px;
        height: 24px;
        min-width: 24px;
      }

      &:hover {
        border: $border-light;
      }

      &.active {
        border: $border-dark;
        font-weight: bold;
        color: $darkest;
      }

      &.primary {
        background-color: $darkest;
        color: white;
        font-weight: bold;

        svg {
          color: white;
        }
      }

      .icon {
        @extend %flex-row-middle;
        svg {
          font-size: 11px;
        }
      }

      .display {
        font-size: 12px;
        line-height: 12px;

        @include xl-down {
          font-size: 10px;
          line-height: 10px;
        }

        @include lg-down {
          display: none;
        }
      }
    }
  }
}
