@import '../../styles/shared.scss';

.opportunity-request-container {
  background-color: $black;
  border-radius: 20px;
  position: relative;
  height: 480px;

  @include md-down {
    height: 280px;
  }

  &:hover {
    .cover-img-container {
      opacity: 0.2;

      img {
        transform: scale(1.05);
      }
    }
  }

  .cover-img-container {
    width: 100%;
    height: 100%;
    border-radius: 20px;
    overflow: hidden;
    opacity: 0.5;
    transition: opacity 0.3s ease;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      transition: transform 0.3s ease;
    }
  }

  .content {
    @extend %flex-col-spread;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    padding: 24px;
    color: white;

    .header-section {
      @extend %flex-row-middle-spread;

      .badges {
        .badge {
          @extend %label;
          font-weight: bold;
          font-size: 12px;
        }
      }

      .action-btns {
        @extend %flex-row-middle;
        gap: 8px;
        .action-btn {
          font-size: 16px;
        }
      }
    }

    .main-section {
      .brand {
        @extend %label;
        font-size: 11px;
        font-weight: bold;
        margin-bottom: 4px;
      }

      .title {
        font-size: 30px;
        font-weight: bold;
        font-family: $font-header;
      }

      .timing-location {
        margin-top: 4px;
        font-size: 12px;
      }
    }

    .footer-section {
      color: white;

      .fee {
        font-size: 30px;
        font-family: $font-header;
        font-weight: bold;
      }

      .full-earnings-outer {
        @extend %flex-row;
        gap: 6px;
        margin-top: 8px;

        .total-icon {
          @extend %flex-center;
          font-size: 8px;
          height: 12px;
          width: 12px;

          svg {
            background-color: rgba(255, 255, 255, 0.4);
            padding: 2px;
            color: white;
            border-radius: 50%;
          }
        }

        .full-earnings-range {
          font-size: 12px;
          line-height: 1;
          font-weight: 500;
          white-space: nowrap;
        }
        .explainer {
          font-size: 10px;
          margin-top: 2px;
        }
      }

      .metadata {
        font-size: 12px;
        margin-top: 8px;
      }
    }
  }
}
